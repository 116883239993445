<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- Group Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>{{
            $t('components.settingsManagement.trip.headline.groupTrip')
          }}</span>
        </div>
        <div
          :key="`groupTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in groupTripSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`groupTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="groupTrip[`${item.variable}`]"
              :haveUnitText="item.haveUnitText"
              :unitText="item.unitText"
              @save="onSave('organization', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- Start Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>{{
            $t('components.settingsManagement.trip.headline.startTrip')
          }}</span>
        </div>
        <div
          :key="`startTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in startTripSettings">
            <SmartToggleInput
              v-if="item.type === 'toggle'"
              :key="itemIndex"
              :rowId="`startTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="startTrip[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`startTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="item.options"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="startTrip[`${item.variable}`]"
              :haveUnitText="item.haveUnitText"
              :unitText="item.unitText"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Pause Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>{{
            $t('components.settingsManagement.trip.headline.pauseTrip')
          }}</span>
        </div>
        <div :key="`pauseTrip_${counter}`" class=" mt-3 pt-5 border-t-2">
          <SmartAppInput
            :key="`pauseTrip_${counter + 0}`"
            :rowId="`pauseTrip_${0}`"
            :type="pauseTripSettings[0].type"
            :label="pauseTripSettings[0].label"
            :options="pauseTripSettings[0].options"
            :placeholder="pauseTripSettings[0].placeholder"
            :description="pauseTripSettings[0].description"
            :isEdit="pauseTripSettings[0].isEdit"
            v-model="pauseTrip[`${pauseTripSettings[0].variable}`]"
            @save="
              onSave('vehicle', `${pauseTripSettings[0].variable}`, $event)
            "
          />
          <SmartToggleInput
            :key="`pauseTrip_${counter + 1}`"
            :type="pauseTripSettings[1].type"
            :label="pauseTripSettings[1].label"
            :placeholder="pauseTripSettings[1].placeholder"
            :description="pauseTripSettings[1].description"
            :onText="pauseTripSettings[1].onText"
            :offText="pauseTripSettings[1].offText"
            :onValue="pauseTripSettings[1].onValue"
            :offValue="pauseTripSettings[1].offValue"
            v-model="pauseTrip[`${pauseTripSettings[1].variable}`]"
            @save="
              onSave('organization', `${pauseTripSettings[1].variable}`, $event)
            "
          />
        </div>
      </div>
      <!-- On Going Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>{{
            $t('components.settingsManagement.trip.headline.onGoingTrip')
          }}</span>
        </div>
        <div
          :key="`onGoingTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in onGoingTripSettings">
            <template v-if="item.type === 'toggle'">
              <SmartToggleInput
                v-if="item.variable === 'force_complete_trip_for_low_battery'"
                :key="itemIndex"
                :label="item.label"
                :options="item.options"
                :placeholder="item.placeholder"
                :description="item.description"
                :onText="item.onText"
                :offText="item.offText"
                :onValue="item.onValue"
                :offValue="item.offValue"
                v-model="onGoingTrip[`${item.variable}`]"
                @save="onSave('vehicle', `${item.variable}`, $event)"
              />
              <SmartToggleInput
                v-else
                :key="itemIndex"
                :label="item.label"
                :options="item.options"
                :placeholder="item.placeholder"
                :description="item.description"
                :onText="item.onText"
                :offText="item.offText"
                :onValue="item.onValue"
                :offValue="item.offValue"
                v-model="onGoingTrip[`${item.variable}`]"
                @save="onSave('organization', `${item.variable}`, $event)"
              />
            </template>
            <template v-else>
              <SmartAppInput
                v-if="
                  item.variable === 'trip_time_limit_in_seconds' &&
                    onGoingTrip.should_end_ride_after_time_exceeded
                "
                :key="itemIndex"
                :rowId="`onGoingTrip_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :isEdit="item.isEdit"
                v-model="onGoingTrip[`${item.variable}`]"
                :haveUnitText="item.haveUnitText"
                :unitText="item.unitText"
                :unitWidthVariant="item.unitWidthVariant"
                @save="onSave('organization', `${item.variable}`, $event)"
              />
              <SmartAppInput
                v-if="
                  item.variable === 'low_battery_level' &&
                    onGoingTrip.force_complete_trip_for_low_battery
                "
                :key="itemIndex"
                :rowId="`onGoingTrip_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :isEdit="item.isEdit"
                v-model="onGoingTrip[`${item.variable}`]"
                :haveUnitText="item.haveUnitText"
                :unitText="item.unitText"
                @save="onSave('vehicle', `${item.variable}`, $event)"
              />
            </template>
          </template>
        </div>
      </div>

      <!-- End Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>{{
            $t('components.settingsManagement.trip.headline.endTrip')
          }}</span>
        </div>
        <div :key="`endTrip_${counter}`" class=" mt-3 pt-5 border-t-2">
          <SmartAppInput
            :key="`endTrip_${counter + 0}`"
            :type="endTripSettings[0].type"
            :label="endTripSettings[0].label"
            :description="endTripSettings[0].description"
            :placeholder="endTripSettings[0].placeholder"
            :options="endTripSettings[0].options"
            :isMultipleChoice="true"
            multipleChoiceType="vehicleType"
            v-model="endTrip[`${endTripSettings[0].variable}`]"
            @save="
              onSave(
                'vehicle',
                `${endTripSettings[0].variable}`,
                JSON.stringify($event)
              )
            "
          />
          <SmartToggleInput
            :key="`endTrip_${counter + 1}`"
            :label="endTripSettings[1].label"
            :description="endTripSettings[1].description"
            :onText="endTripSettings[1].onText"
            :offText="endTripSettings[1].offText"
            :onValue="endTripSettings[1].onValue"
            :offValue="endTripSettings[1].offValue"
            v-model="endTrip[`${endTripSettings[1].variable}`]"
            @save="onSave('vehicle', `${endTripSettings[1].variable}`, $event)"
          />
          <SmartAppInput
            :key="`endTrip_${counter + 2}`"
            :rowId="`endTrip_${2}`"
            :type="endTripSettings[2].type"
            :label="endTripSettings[2].label"
            :description="endTripSettings[2].description"
            :isEdit="endTripSettings[2].isEdit"
            :options="endTripSettings[2].options"
            v-model="endTrip[`${endTripSettings[2].variable}`]"
            :haveUnitText="endTripSettings[2].haveUnitText"
            :unitText="endTripSettings[2].unitText"
            :unitWidthVariant="endTripSettings[2].unitWidthVariant"
            @save="
              onSave('organization', `${endTripSettings[2].variable}`, $event)
            "
          />
          <SmartToggleInput
            :key="`endTrip_${counter + 3}`"
            :label="endTripSettings[3].label"
            :description="endTripSettings[3].description"
            :onText="endTripSettings[3].onText"
            :offText="endTripSettings[3].offText"
            :onValue="endTripSettings[3].onValue"
            :offValue="endTripSettings[3].offValue"
            v-model="endTrip[`${endTripSettings[3].variable}`]"
            @save="onSave('vehicle', `${endTripSettings[3].variable}`, $event)"
          />
          <SmartToggleInput
            :key="`endTrip_${counter + 4}`"
            :label="endTripSettings[4].label"
            :description="endTripSettings[4].description"
            :onText="endTripSettings[4].onText"
            :offText="endTripSettings[4].offText"
            :onValue="endTripSettings[4].onValue"
            :offValue="endTripSettings[4].offValue"
            v-model="endTrip[`${endTripSettings[4].variable}`]"
            @save="onSave('vehicle', `${endTripSettings[4].variable}`, $event)"
          />
          <SmartToggleInput
            :key="`endTrip_${counter + 5}`"
            :rowId="`endTrip_${5}`"
            :type="endTripSettings[5].type"
            :label="endTripSettings[5].label"
            :description="endTripSettings[5].description"
            :onText="endTripSettings[5].onText"
            :offText="endTripSettings[5].offText"
            :onValue="endTripSettings[5].onValue"
            :offValue="endTripSettings[5].offValue"
            v-model="endTrip[`${endTripSettings[5].variable}`]"
            @save="onSave('vehicle', `${endTripSettings[5].variable}`, $event)"
          />
          <SmartAppInput
            v-if="endTrip.complete_trip_after_failed_lock_attempts"
            :key="`endTrip_${counter + 6}`"
            :rowId="`endTrip_${6}`"
            :type="endTripSettings[6].type"
            :label="endTripSettings[6].label"
            :description="endTripSettings[6].description"
            :isEdit="endTripSettings[6].isEdit"
            :options="endTripSettings[6].options"
            v-model="endTrip[`${endTripSettings[6].variable}`]"
            @save="onSave('vehicle', `${endTripSettings[6].variable}`, $event)"
          />
          <SmartAppInput
            :key="`endTrip_${counter + 7}`"
            :rowId="`endTrip_${7}`"
            :type="endTripSettings[7].type"
            :label="endTripSettings[7].label"
            :description="endTripSettings[7].description"
            :isEdit="endTripSettings[7].isEdit"
            :options="endTripSettings[7].options"
            v-model="endTrip[`${endTripSettings[7].variable}`]"
            @save="
              onSave('organization', `${endTripSettings[7].variable}`, $event)
            "
          />
          <SmartToggleInput
            :key="`endTrip_${counter + 8}`"
            :rowId="`endTrip_${8}`"
            :type="endTripSettings[8].type"
            :label="endTripSettings[8].label"
            :description="endTripSettings[8].description"
            :onText="endTripSettings[8].onText"
            :offText="endTripSettings[8].offText"
            :onValue="endTripSettings[8].onValue"
            :offValue="endTripSettings[8].offValue"
            v-model="endTrip[`${endTripSettings[8].variable}`]"
            @save="
              onSave('organization', `${endTripSettings[8].variable}`, $event)
            "
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { useEndpoints } from '@/composables'
import { VehicleSettingsConfig } from '@/config/SettingsConfig'
import { EventBus } from '@/utils'
export default {
  name: 'TripSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      groupTrip: {
        number_of_guests_allowed_per_trip: 0,
      },
      startTrip: {
        min_power_level_for_trip: 0,
        allow_unlock_outside_service_area: false,
        allow_unlock_inside_restricted_area: false,
        manual_lock_sms_enabled: false,
      },
      onGoingTrip: {
        force_complete_trip_for_rider_neg_balance: true,
        should_end_ride_after_time_exceeded: false,
        trip_time_limit_in_seconds: 0,
        force_complete_trip_for_low_battery: false,
        low_battery_level: 0,
        is_charging_required_to_end_ride: false,
      },
      pauseTrip: {
        pause_ride_hardware_action: '',
        allow_pause_inside_restricted_area: null,
      },
      endTrip: {
        seconds_to_complete_trip_after_lock_attempts: 0,
        allow_locking_outside_service_area: true,
        allow_locking_inside_restricted_area: true,
        complete_trip_after_failed_lock_attempts: '',
        required_lock_attempts_to_complete_trip: '',
        end_ride_location_verification_type: '',
        complete_trip_after_vehicle_successfully_locked: false,
        vehicle_types_for_required_helmet_inside_box_verification: [],
      },
      groupTripSettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.trip.groupTrip.maximumGuestsPermittedPerTrip.title'
          ),
          placeholder: 'e.g 3',
          description: this.$t(
            'components.settingsManagement.trip.groupTrip.maximumGuestsPermittedPerTrip.description'
          ),
          variable: 'number_of_guests_allowed_per_trip',
          haveUnitText: true,
          unitText: 'Guest',
          isEdit: false,
        },
      ],
      startTripSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.trip.startTrip.minimumBatteryPowerForTrip.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.startTrip.minimumBatteryPowerForTrip.description'
          ),
          options: [
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
            { value: 11, text: '11' },
            { value: 12, text: '12' },
            { value: 13, text: '13' },
            { value: 14, text: '14' },
            { value: 15, text: '15' },
            { value: 16, text: '16' },
            { value: 17, text: '17' },
            { value: 18, text: '18' },
            { value: 19, text: '19' },
            { value: 20, text: '20' },
          ],
          variable: 'min_power_level_for_trip',
          haveUnitText: true,
          unitText: '%',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.startTrip.allowTripsOutsideGeofence.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.startTrip.allowTripsOutsideGeofence.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'allow_unlock_outside_service_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.startTrip.allowTripsInsideRestrictedAreas.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.startTrip.allowTripsInsideRestrictedAreas.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'allow_unlock_inside_restricted_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.startTrip.allowManualLockViaSMS.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.startTrip.allowManualLockViaSMS.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'manual_lock_sms_enabled',
          isEdit: false,
        },
      ],
      pauseTripSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.trip.pauseTrip.actionWhenRiderPausesTrip.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.pauseTrip.actionWhenRiderPausesTrip.description'
          ),
          options: [
            { value: 'THROTTLE_OFF', text: 'Turn Throttle Off' },
            { value: 'LOCK', text: 'Lock the vehicle' },
          ],
          variable: 'pause_ride_hardware_action',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.pauseTrip.allowRiderToPauseTripInRestrictedAreas.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.pauseTrip.allowRiderToPauseTripInRestrictedAreas.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'allow_pause_inside_restricted_area',
          isEdit: false,
        },
      ],
      onGoingTripSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.endRideWhenNegativeBalanceOccurs.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.endRideWhenNegativeBalanceOccurs.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'force_complete_trip_for_rider_neg_balance',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.chargingStatusBeforeEndingRide.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.chargingStatusBeforeEndingRide.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_charging_required_to_end_ride',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.terminateTripUponTimeLimitExceedance.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.terminateTripUponTimeLimitExceedance.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_end_ride_after_time_exceeded',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.tripDuration.title'
          ),

          placeholder: 'e.g 300',
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.tripDuration.description'
          ),
          variable: 'trip_time_limit_in_seconds',
          haveUnitText: true,
          unitText: 'Seconds',
          unitWidthVariant: 'large',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.completeTripOnLowBattery.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.completeTripOnLowBattery.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'force_complete_trip_for_low_battery',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.trip.onGoingTrip.lowBatteryThresholdForForcedTripCompletion.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.onGoingTrip.lowBatteryThresholdForForcedTripCompletion.description'
          ),
          variable: 'low_battery_level',
          haveUnitText: true,
          unitText: '%',
          isEdit: false,
        },
      ],
      // Caution: End settings serial could not broken
      endTripSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.vehicleTypesForRequiredHelmetInsideBoxVerification.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.vehicleTypesForRequiredHelmetInsideBoxVerification.description'
          ),
          options: [
            {
              text: 'Scooter',
              value: 'P',
            },
            {
              text: 'Scooter Pro',
              value: 'PP',
            },
            {
              text: 'E-Bike',
              value: 'E',
            },
            {
              text: 'Bike',
              value: 'S',
            },
            {
              text: 'SuperCoco',
              value: 'C',
            },
            {
              text: 'Kenota',
              value: 'K',
            },
            {
              text: 'Moped',
              value: 'M',
            },
            {
              text: 'None',
              value: 'NONE',
            },
          ],
          variable: 'vehicle_types_for_required_helmet_inside_box_verification',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.allowImmediateVehicleRelease.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.allowImmediateVehicleRelease.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'sync_vehicle_ride_status_during_trip_completion',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.timeAfterValidLockRequest.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.timeAfterValidLockRequest.description'
          ),
          variable: 'seconds_to_complete_trip_after_lock_attempts',
          haveUnitText: true,
          unitText: 'Seconds',
          unitWidthVariant: 'large',
          isEdit: false,
        },

        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.allowEndingRideOutsideServiceArea.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.allowEndingRideOutsideServiceArea.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'allow_locking_outside_service_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.allowEndingRideInsideRestrictedArea.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.allowEndingRideInsideRestrictedArea.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'allow_locking_inside_restricted_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.completeTripAfterFailedLockAttempts.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.completeTripAfterFailedLockAttempts.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'complete_trip_after_failed_lock_attempts',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.numberOfRequiredLockAttempts.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.numberOfRequiredLockAttempts.description'
          ),
          options: [
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 5, text: 5 },
          ],
          variable: 'required_lock_attempts_to_complete_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.endRideLocationVerificationSource.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.endRideLocationVerificationSource.description'
          ),
          options: [
            { value: 'R', text: 'Rider' },
            { value: 'V', text: 'Vehicle' },
            { value: 'R&V', text: 'Rider & Vehicle' },
            { value: 'R|V', text: 'Rider or Vehicle' },
          ],
          variable: 'end_ride_location_verification_type',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.trip.endTrip.endRideAfterVehicleLockAcknowledgment.title'
          ),
          description: this.$t(
            'components.settingsManagement.trip.endTrip.endRideAfterVehicleLockAcknowledgment.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'complete_trip_after_vehicle_successfully_locked',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    const orgReq = this.$http.get(useEndpoints.organization.details(this.orgId))
    const vehicleReq = this.$http.get(
      VehicleSettingsConfig.api.index(this.orgId)
    )
    const requests = [orgReq, vehicleReq]

    try {
      const responses = await Promise.allSettled(requests)
      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            // console.log('test-r', response.value.data)
            const orgResData = response.value.data

            //group trip
            this.groupTrip.number_of_guests_allowed_per_trip =
              orgResData.number_of_guests_allowed_per_trip

            //pause trip
            this.pauseTrip.allow_pause_inside_restricted_area =
              orgResData.allow_pause_inside_restricted_area

            //going trip
            this.onGoingTrip.force_complete_trip_for_rider_neg_balance =
              orgResData.force_complete_trip_for_rider_neg_balance

            this.onGoingTrip.is_charging_required_to_end_ride =
              orgResData.is_charging_required_to_end_ride

            this.onGoingTrip.should_end_ride_after_time_exceeded =
              orgResData.should_end_ride_after_time_exceeded

            this.onGoingTrip.trip_time_limit_in_seconds =
              orgResData.trip_time_limit_in_seconds

            //end trip
            this.endTrip.seconds_to_complete_trip_after_lock_attempts =
              orgResData.seconds_to_complete_trip_after_lock_attempts

            this.endTrip.end_ride_location_verification_type =
              orgResData.end_ride_location_verification_type
            this.endTrip.complete_trip_after_vehicle_successfully_locked =
              orgResData.complete_trip_after_vehicle_successfully_locked
          }
          if (
            response.value.config.url ===
            VehicleSettingsConfig.api.index(this.orgId)
          ) {
            const vehicleResData = response.value.data
            //start trip
            this.startTrip.min_power_level_for_trip =
              vehicleResData.min_power_level_for_trip

            this.startTrip.allow_unlock_outside_service_area =
              vehicleResData.allow_unlock_outside_service_area

            this.startTrip.allow_unlock_inside_restricted_area =
              vehicleResData.allow_unlock_inside_restricted_area

            this.startTrip.manual_lock_sms_enabled =
              vehicleResData.manual_lock_sms_enabled
            //pause trip
            this.pauseTrip.pause_ride_hardware_action =
              vehicleResData.pause_ride_hardware_action

            //going trip
            this.onGoingTrip.force_complete_trip_for_low_battery =
              vehicleResData.force_complete_trip_for_low_battery

            this.onGoingTrip.low_battery_level =
              vehicleResData.low_battery_level

            //end trip
            this.endTrip.allow_locking_outside_service_area =
              vehicleResData.allow_locking_outside_service_area
            this.endTrip.allow_locking_inside_restricted_area =
              vehicleResData.allow_locking_inside_restricted_area
            this.endTrip.complete_trip_after_failed_lock_attempts =
              vehicleResData.complete_trip_after_failed_lock_attempts

            this.endTrip.required_lock_attempts_to_complete_trip =
              vehicleResData.required_lock_attempts_to_complete_trip

            this.endTrip.sync_vehicle_ride_status_during_trip_completion =
              vehicleResData.sync_vehicle_ride_status_during_trip_completion

            this.endTrip.vehicle_types_for_required_helmet_inside_box_verification = this.getVehicleTypes(
              vehicleResData.vehicle_types_for_required_helmet_inside_box_verification
            )
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            message = `Org Details: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            VehicleSettingsConfig.api.index(this.orgId)
          ) {
            message = `Vehicle Settings: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'groupTrip') {
        this.groupTripSettings = this.groupTripSettings.map(
          (item, itemIndex) => {
            if (id === `groupTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'groupTrip'
      }
      if (idPrefix === 'startTrip') {
        this.startTripSettings = this.startTripSettings.map(
          (item, itemIndex) => {
            if (id === `startTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'startTrip'
      }
      if (idPrefix === 'pauseTrip') {
        this.pauseTripSettings = this.pauseTripSettings.map(
          (item, itemIndex) => {
            if (id === `pauseTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'pauseTrip'
      }
      if (idPrefix === 'onGoingTrip') {
        this.onGoingTripSettings = this.onGoingTripSettings.map(
          (item, itemIndex) => {
            if (id === `onGoingTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'onGoingTrip'
      }
      if (idPrefix === 'endTrip') {
        this.endTripSettings = this.endTripSettings.map((item, itemIndex) => {
          if (id === `endTrip_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'endTrip'
      }
    })
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'groupTrip') {
          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.onGoingTripSettings = this.onGoingTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'startTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.onGoingTripSettings = this.onGoingTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'pauseTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.onGoingTripSettings = this.onGoingTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'onGoingTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'endTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.onGoingTripSettings = this.onGoingTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    // getEndTripSettings() {
    //   const allEndTripSettings = this.endTripSettings
    //   if (this.endTrip.complete_trip_after_failed_lock_attempts === false) {
    //     const removeSettingsKey = ['required_lock_attempts_to_complete_trip']
    //     const filteredSettings = allEndTripSettings.filter(
    //       (item) => !removeSettingsKey.includes(item.variable)
    //     )
    //     console.log('filteredSettings-1', filteredSettings)
    //     return filteredSettings
    //   } else {
    //     return this.endTripSettings
    //   }
    // },
  },
  methods: {
    getVehicleTypes(data) {
      console.log('getVehicleTypes', data)
      if (data.length > 0) {
        return data
      } else {
        return ['NONE']
      }
    },
    async onSave(apiGroup, key, val) {
      console.log('apiGroup', apiGroup, 'key', key, 'val', val)
      this.counter++
      let url = ''
      if (apiGroup === 'organization') {
        url = useEndpoints.organization.update(this.orgId)
      }
      if (apiGroup === 'vehicle') {
        url = VehicleSettingsConfig.api.index(this.orgId)
      }

      let toastTitle, toastText

      if (key === 'sync_vehicle_ride_status_during_trip_completion') {
        toastTitle = `Allow Forcefully Make Vehicle Free immediately for end ride request`
        toastText = `Allow Forcefully Make Vehicle Free immediately for end ride request has been updated`
      }

      if (key === 'number_of_guests_allowed_per_trip') {
        toastTitle = `Number of guests allowed per trip`
        toastText = `Number of guests allowed per trip has been updated`
      }
      if (key === 'min_power_level_for_trip') {
        toastTitle = `Trip Minimum Power`
        toastText = `Minimum power for starting trip has been updated`
      }
      if (key === 'complete_trip_after_failed_lock_attempts') {
        toastTitle = `Complete trip after failed lock attempts`
        toastText = `Complete trip after failed lock attempts has been updated`
      }
      if (key === 'required_lock_attempts_to_complete_trip') {
        toastTitle = `Required lock attempts to complete trip`
        toastText = `Required lock attempts to complete trip has been updated`
      }

      if (key === 'pause_ride_hardware_action') {
        toastTitle = `Pause ride hardware action`
        toastText = `Pause ride hardware action has been updated`
      }
      if (key === 'allow_pause_inside_restricted_area') {
        toastTitle = `Pause inside restricted area`
        toastText = `Pause inside restricted area has been updated`
      }
      if (key === 'allow_unlock_outside_service_area') {
        toastTitle = `Outside of geofence`
        toastText = `Outside of geofence action has been updated`
      }
      if (key === 'allow_unlock_inside_restricted_area') {
        toastTitle = `Inside restricted area`
        toastText = `Inside restricted area action is has been updated`
      }
      if (key === 'end_ride_location_verification_type') {
        toastTitle = `Ride Verification`
        toastText = `End ride location verification settings has been updated`
      }
      if (key === 'force_complete_trip_for_rider_neg_balance') {
        toastTitle = `Force complete trip for rider negative balance`
        toastText = `Force complete trip for rider negative balance has been updated`
      }
      if (key === 'should_end_ride_after_time_exceeded') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'trip_time_limit_in_seconds') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'force_complete_trip_for_low_battery') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'trip_time_limit_in_seconds') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'low_battery_level') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'complete_trip_after_vehicle_successfully_locked') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'manual_lock_sms_enabled') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'is_charging_required_to_end_ride') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }

      if (key === 'seconds_to_complete_trip_after_lock_attempts') {
        toastTitle = `Allow locking outside service area`
        toastText = `Seconds_to_complete_trip_after_lock_attempts has been updated`
      }

      if (key === 'allow_locking_outside_service_area') {
        toastTitle = `Allow locking outside service area`
        toastText = `Allow locking outside service area has been updated`
      }

      if (key === 'seconds_to_complete_trip_after_lock_attempts') {
        toastTitle = `Trip After Valid Lock Request(Seconds)`
        toastText = `Trip After Valid Lock Request(Seconds) has been updated`
      }
      if (key === 'allow_locking_inside_restricted_area') {
        toastTitle = `Success`
        toastText = `Status has been updated`
      }
      if (key === 'vehicle_types_for_required_helmet_inside_box_verification') {
        toastTitle = `Success`
        toastText = `Update Successfully`
      }

      let data = new FormData()
      if (key === 'vehicle_types_for_required_helmet_inside_box_verification') {
        if (val.includes('NONE')) {
          data.append(key, JSON.stringify([]))
        } else {
          data.append(key, val)
        }
      } else {
        data.append(key, val)
      }

      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.onGoingTripSettings = this.onGoingTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
  },
}
</script>
<style lang=""></style>
