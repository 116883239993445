<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- Group Trip -->
      <div class="w-full">
        <div class="flex items-center  justify-between">
          <div class="font-bold text-22px text-oCharcoal">
            <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
            <span>
              {{ $t('components.settingsManagement.iot.headline.iotSettings') }}
            </span>
          </div>
          <div
            class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end cursor-pointer"
          >
            <div v-if="!isIotSettingsEdit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                @click="handleIotSettingsEdit"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
          </div>
          <div v-if="isIotSettingsEdit" class="flex ml-3 mt-3 mb-4">
            <anchor-button
              @click="handleIotSettingsCancel"
              :text="$t('components.stepNavigation.cancel')"
              width="6rem"
            />
            <anchor-button
              @click="updateIotSettings"
              :text="$t('components.stepNavigation.save')"
              variant="success"
              width="6rem"
              class="ml-4"
            />
          </div>
        </div>
        <div :key="`IotSettings_${counter}`" class=" mt-3 pt-5 border-t-2">
          <!-- domains -->
          <section>
            <!-- <div class="section-name" v-text="`Domains`" /> -->
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>{{
                $t('components.settingsManagement.iot.headline.manageIot')
              }}</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddLockTypeField"
              >
                <i class="fas fa-plus-circle" style="color: black"></i>
              </div>
            </div>

            <!-- {{ lockTypes }} -->
            <div
              class="flex items-center"
              v-for="(item, index) in lockTypes"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-4/12">
                  <AppInput
                    v-model="item.type"
                    type="richselect"
                    :name="`IoT Type ${index}`"
                    infoDescription="Enter IOT type"
                    :isInfoEnabled="false"
                    :label="
                      $t('components.settingsManagement.iot.iotSettings.type')
                    "
                    value-attribute="value"
                    text-attribute="display_name"
                    placeholder="Select IoT Type"
                    :options="lockIots"
                    :disabled="!isIotSettingsEdit"
                    hide-search-box
                  />
                </div>

                <div class="w-4/12">
                  <AppInput
                    v-model="item.domain"
                    type="text"
                    :name="`Domain ${index}`"
                    infoDescription="Enter IOT domain name"
                    :isInfoEnabled="false"
                    rules=""
                    :label="
                      $t('components.settingsManagement.iot.iotSettings.domain')
                    "
                    placeholder="Write a domain"
                    :disabled="!isIotSettingsEdit"
                  />
                </div>

                <div class="w-4/12">
                  <AppInput
                    v-model="item.port"
                    type="number"
                    :name="`Port ${index}`"
                    infoDescription="Enter port number"
                    :isInfoEnabled="false"
                    rules=""
                    :label="
                      $t('components.settingsManagement.iot.iotSettings.port')
                    "
                    placeholder="Write a port"
                    :disabled="!isIotSettingsEdit"
                  />
                </div>
              </div>

              <div
                class="flex justify-center w-1/12 item-center"
                v-if="isIotSettingsEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveLockTypeField(index)"
                >
                  <i class="fas fa-minus-circle" style="color: #d90a20"></i>
                </div>
              </div>
            </div>
          </section>
          <!-- /domains -->
        </div>
      </div>
      <!-- Okai Dashboard Configuration -->
      <section v-if="isOkaiIotType" class="mt-10">
        <div class="font-bold text-22px text-oCharcoal">
          <span>{{
            $t('components.settingsManagement.iot.headline.okaiSettings')
          }}</span>
        </div>
        <div :key="`okai_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in okaiSettings">
            <SmartAppInput
              v-if="item.variable === 'speed_mode_display_unit_for_okai'"
              :key="itemIndex"
              :rowId="`okai_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="okai[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`okai_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="okai[`${item.variable}`]"
              @save="onSave('org', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </section>

      <!-- Segway Dashboard Configuration -->
      <section v-if="isSegwayIotType" class="mt-10">
        <div class="font-bold text-22px text-oCharcoal">
          <span>{{
            $t('components.settingsManagement.iot.headline.segwaySettings')
          }}</span>
        </div>
        <div :key="`segway_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in segwaySettings">
            <SmartAppInput
              v-if="item.variable === 'speed_mode_display_unit_for_segway'"
              :key="itemIndex"
              :rowId="`segway_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="segway[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`segway_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="segway[`${item.variable}`]"
              @save="onSave('org', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </section>
      <!-- /Segway Dashboard Configuration -->
    </div>
  </section>
</template>
<script>
import { useEndpoints } from '@/composables'
import AnchorButton from '@/components/form/AnchorButton'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils'
export default {
  name: 'IotSettings',
  components: {
    AnchorButton,
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      isIotSettingsEdit: false,
      segway: {
        segway_client_id: '',
        segway_client_secret: '',
        segway_portal_base_urL: '',
        segway_user_id: '',
        speed_mode_display_unit_for_segway: '',
      },
      okai: {
        okai_command_frame_pass: '',
        speed_mode_display_unit_for_okai: '',
      },
      lockTypes: [],
      lockIots: [],
      toDeleteLockTypes: [],
      isSegwayIotType: false,
      isOkaiIotType: false,
      dataChangeStatus: false,
      okaiSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.iot.okai.okaiCommandFramePassword.title'
          ),
          placeholder: `e.g. 4xhI4fk`,
          description: this.$t(
            'components.settingsManagement.iot.okai.okaiCommandFramePassword.description'
          ),
          variable: 'okai_command_frame_pass',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.iot.okai.speedModelDisplayUnit.title'
          ),
          description: this.$t(
            'components.settingsManagement.iot.okai.speedModelDisplayUnit.description'
          ),
          options: [
            { value: 'KM', text: 'Kilometer' },
            { value: 'YD', text: 'Yard' },
          ],
          variable: 'speed_mode_display_unit_for_okai',
          isEdit: false,
        },
      ],

      segwaySettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.iot.segway.segwayClientId.title'
          ),
          placeholder: `e.g. 4xhI4fk`,
          description: this.$t(
            'components.settingsManagement.iot.segway.segwayClientId.description'
          ),
          variable: 'segway_client_id',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.iot.segway.segwaySecretKey.title'
          ),
          placeholder: `e.g. HkiC-45k`,
          description: this.$t(
            'components.settingsManagement.iot.segway.segwaySecretKey.description'
          ),
          variable: 'segway_client_secret',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.iot.segway.segwayPortalBaseUrl.title'
          ),
          placeholder: 'e.g. portal.website.com',
          description: this.$t(
            'components.settingsManagement.iot.segway.segwayPortalBaseUrl.description'
          ),
          variable: 'segway_portal_base_urL',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.iot.segway.segwayUsertId.title'
          ),
          placeholder: 'e.g. 190288',
          description: this.$t(
            'components.settingsManagement.iot.segway.segwayUsertId.description'
          ),
          variable: 'segway_user_id',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.iot.segway.speedModelDisplayUnit.title'
          ),
          description: this.$t(
            'components.settingsManagement.iot.segway.speedModelDisplayUnit.description'
          ),
          options: [
            { value: 'KM', text: 'Kilometer' },
            { value: 'M', text: 'Mile' },
          ],
          variable: 'speed_mode_display_unit_for_segway',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    const iotReq = this.$http.get(
      useEndpoints.organization.vehicleTypeSettings.index(this.orgId)
    )
    const lockIotReq = this.$http.get(useEndpoints.dropdown.lockTypes())

    const orgDetailsReq = this.$http.get(
      useEndpoints.organization.details(this.orgId)
    )

    const vehicleSettingsReq = this.$http.get(
      useEndpoints.settings.vehicle.index(this.orgId)
    )

    const requests = [iotReq, lockIotReq, orgDetailsReq, vehicleSettingsReq]

    try {
      const responses = await Promise.allSettled(requests)

      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            useEndpoints.organization.vehicleTypeSettings.index(this.orgId)
          ) {
            this.lockTypes = response.value.data?.data.map((entry) => {
              return {
                id: entry.id,
                type: entry.iot_category,
                name: entry.iot_category_name,
                domain: entry.domain ? entry.domain : '',
                port: entry.port,
                isEdit: false,
              }
            })
          }
          if (response.value.config.url === useEndpoints.dropdown.lockTypes()) {
            this.lockIots = response.value.data?.data
          }
          if (
            response.value.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            this.segway.segway_client_id = response.value.data.segway_client_id
            this.segway.segway_user_id = response.value.data.segway_user_id
            this.segway.segway_client_secret =
              response.value.data.segway_client_secret
            this.segway.segway_portal_base_urL =
              response.value.data.segway_portal_base_urL
            this.okai.okai_command_frame_pass =
              response.value.data.okai_command_frame_pass
          }
          if (
            response.value.config.url ===
            useEndpoints.settings.vehicle.index(this.orgId)
          ) {
            this.segway.speed_mode_display_unit_for_segway =
              response.value.data.speed_mode_display_unit_for_segway
            this.okai.speed_mode_display_unit_for_okai =
              response.value.data.speed_mode_display_unit_for_okai
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            useEndpoints.organization.vehicleTypeSettings.index(this.orgId)
          ) {
            message = `IOT Type Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url === useEndpoints.dropdown.lockTypes()
          ) {
            message = `IOT Types: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            message = `Org Details: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.settings.vehicle.index(this.orgId)
          ) {
            message = `Vehicle Settings: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  async mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      console.log('idPrefix', idPrefix)
      if (idPrefix === 'okai') {
        this.okaiSettings = this.okaiSettings.map((item, itemIndex) => {
          if (id === `okai_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'okai'
      }

      if (idPrefix === 'segway') {
        this.segwaySettings = this.segwaySettings.map((item, itemIndex) => {
          if (id === `segway_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'segway'
      }
    })
  },
  watch: {
    isIotSettingsEdit: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.okaiSettings = this.okaiSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.segwaySettings = this.segwaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'okai') {
          this.isIotSettingsEdit = false
          this.segwaySettings = this.segwaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'segway') {
          this.isIotSettingsEdit = false
          this.okaiSettings = this.okaiSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
    lockTypes: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        this.isOkaiIotType = updatedData.some((iot) => {
          const type = iot.type.split('_')[0]
          if (type === 'OKAI') {
            return true
          } else {
            return false
          }
        })

        this.isSegwayIotType = updatedData.some((iot) => {
          const type = iot.type.split('_')[0]
          if (type === 'SEGWAY') {
            return true
          } else {
            return false
          }
        })

        if (this.counter > 0) {
          this.dataChangeStatus = true
        }
        this.counter++
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  methods: {
    handleIotSettingsEdit() {
      this.isIotSettingsEdit = true
    },
    handleIotSettingsCancel() {
      this.isIotSettingsEdit = false
      if (this.dataChangeStatus) {
        this.$http
          .get(useEndpoints.organization.vehicleTypeSettings.index(this.orgId))
          .then((res) => {
            this.lockTypes = res.data?.data.map((entry) => {
              return {
                id: entry.id,
                type: entry.iot_category,
                name: entry.iot_category_name,
                domain: entry.domain ? entry.domain : '',
                port: entry.port,
                isEdit: false,
              }
            })
          })
        this.dataChangeStatus = false
        this.counter = 0
      }
    },
    async updateIotSettings() {
      let iotTypeRequests = []
      if (this.lockTypes.length) {
        this.lockTypes.forEach((element) => {
          console.log(`Elem ${element.type}`, element)
          const isUpdateReq = 'id' in element
          const iotTypeMethod = isUpdateReq ? 'PATCH' : 'POST'
          const iotTypeUrl = isUpdateReq
            ? useEndpoints.organization.vehicleTypeSettings.update(element.id)
            : useEndpoints.organization.vehicleTypeSettings.create(this.orgId)

          const iotData = new FormData()
          iotData.append('iot_category', element.type)

          if (typeof element.domain === 'string')
            iotData.append('domain', element.domain)

          if (element.port) {
            if (`${element.port}`.trim() === '' || !isNaN(element.port)) {
              iotData.append('port', element.port)
            }
          }
          // console.log(`${element.type}`, iotData)

          const iotReq = this.$http({
            url: iotTypeUrl,
            method: iotTypeMethod,
            data: iotData,
          })
          iotTypeRequests.push(iotReq)
        })
      }

      if (this.isIotSettingsEdit && this.toDeleteLockTypes.length) {
        this.toDeleteLockTypes.forEach((element) => {
          if ('id' in element) {
            const iotReq = this.$http({
              url: useEndpoints.organization.vehicleTypeSettings.update(
                element.id
              ),
              method: 'DELETE',
            })
            iotTypeRequests.push(iotReq)
          }
        })
      }

      const requests = [...iotTypeRequests]

      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread(() => {
            this.isIotSettingsEdit = false
            this.$notify({
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'IoT Settings has been updated',
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occurred!', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
    },
    onAddLockTypeField() {
      if (this.isIotSettingsEdit) {
        this.lockTypes.unshift({ type: '', domain: '', port: '' })
      }
    },
    onRemoveLockTypeField(index) {
      if (this.isIotSettingsEdit) {
        let toRemoveType = this.lockTypes[index]
        if (
          this.lockTypes.filter((entry) => entry.type === toRemoveType.type)
            .length > 0
        )
          this.toDeleteLockTypes.push(this.lockTypes[index])

        this.lockTypes.splice(index, 1)
      }
    },
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'org') {
        url = useEndpoints.organization.update(this.orgId)
      }
      if (apiGroup === 'vehicle') {
        url = useEndpoints.settings.vehicle.index(this.orgId)
      }

      let data = new FormData()
      data.append(key, val)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.okaiSettings = this.okaiSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.segwaySettings = this.segwaySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Successfully Updated',
            },
            3000
          )
        })
        .catch((err) => {
          // console.log('test-error', { err })

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
  },
}
</script>
<style lang=""></style>
